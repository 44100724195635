<app-page-heading heading="Our Recipes" breadcrumbText="Recipes"></app-page-heading>

<div class="page-body-wrapper">
    <div class="page-body-content-full-width">
        <app-video-player class="top-video-player" [isLinkExternal]="true" youtubeVideoId="mD2rWEowr-w"
            title="Cat Riding a Scooter" viewMoreLink="https://youtube.com" viewMoreLinkText="See More On Youtube"
            previewImageUrl="assets/images/video-background.png"></app-video-player>
    </div>

    <div class="page-body-content">
        <app-section-heading heading="Get Cooking" subHeading="More Recipes"></app-section-heading>
    </div>

    <app-card-carousel [itemsLength]="moreRecipesCardsContent.length"
        (visibleItemIndexes)="visibleFeaturedRecipesIndexes = $event">

        <app-card class="workshop-card" *ngFor="let index of visibleFeaturedRecipesIndexes"
            [cardImageUrl]="moreRecipesCardsContent[index].imageUrl"
            [cardHeading]="moreRecipesCardsContent[index].title"
            [cardSubHeading]="moreRecipesCardsContent[index].subHeading"
            [cardBodyContent]="moreRecipesCardsContent[index].description"
            [cardCTALink]="moreRecipesCardsContent[index].link" [cardCTAText]="'Get Cooking'" [isCTAButton]="true">

        </app-card>
    </app-card-carousel>


    <div class="page-body-content">
        <app-section-heading heading="Browse by type" subHeading="Meal"></app-section-heading>
    </div>

    <app-card-carousel [itemsLength]="mealTypeCardsContent.length"
        (visibleItemIndexes)="visibleMealTypesIndexes = $event">

        <app-card class="workshop-card" *ngFor="let index of visibleMealTypesIndexes"
            [cardImageUrl]="mealTypeCardsContent[index].imageUrl" [cardCTALink]="mealTypeCardsContent[index].link"
            [cardCTAText]="mealTypeCardsContent[index].ctaText" [isCTAButton]="true">

        </app-card>
    </app-card-carousel>

    <div class="page-body-content">
        <app-section-heading heading="Browse by type" subHeading="Food Innovator Favourites"></app-section-heading>
    </div>

    <app-card-carousel [itemsLength]="foodInnovatorFavouritesCardsContent.length"
        (visibleItemIndexes)="visibleFoodInnovatorFavouritesIndexes = $event">

        <app-card class="workshop-card" *ngFor="let index of visibleFoodInnovatorFavouritesIndexes"
            [cardHeading]="foodInnovatorFavouritesCardsContent[index].title"
            [cardSubHeading]="foodInnovatorFavouritesCardsContent[index].subHeading"
            [cardBodyContent]="foodInnovatorFavouritesCardsContent[index].description"
            [cardImageUrl]="foodInnovatorFavouritesCardsContent[index].imageUrl"
            [cardCTALink]="foodInnovatorFavouritesCardsContent[index].link" [cardCTAText]="'View Recipe'"
            [isCTAButton]="true">

        </app-card>
    </app-card-carousel>

    <div class="page-body-content">
        <app-section-heading heading="Innovation Days"></app-section-heading>
    </div>

    <app-card-carousel [itemsLength]="innovationDayCardsContent.length"
        (visibleItemIndexes)="visibleInnovationDayCardsContentIndexes = $event">
        <app-card class="workshop-card" *ngFor="let index of visibleInnovationDayCardsContentIndexes"
            [cardImageUrl]="innovationDayCardsContent[index].imageUrl"
            [cardHeading]="innovationDayCardsContent[index].title"
            [cardBodyContent]="innovationDayCardsContent[index].description"
            [cardCTALink]="innovationDayCardsContent[index].link" [cardCTAText]="'More Info'" [isCTAButton]="false">
        </app-card>
    </app-card-carousel>
</div>