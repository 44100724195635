import { Component } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { VideoPlayerComponent } from '../../shared/video-player/video-player.component';
import { SectionHeadingComponent } from '../../shared/section-heading/section-heading.component';
import { CardCarouselComponent } from '../../shared/card-carousel/card-carousel.component';
import { CardComponent } from '../../shared/card/card.component';
import { NgFor, NgForOf } from '@angular/common';

@Component({
  selector: 'app-recipes',
  standalone: true,
  imports: [PageHeadingComponent, VideoPlayerComponent, SectionHeadingComponent, CardCarouselComponent, CardComponent, NgForOf, NgFor],
  templateUrl: './recipes.component.html',
  styleUrl: './recipes.component.scss'
})
export class RecipesComponent {
  public moreRecipesCardsContent: {
    imageUrl: string,
    title: string,
    subHeading: string,
    description: string,
    link: string,
  }[] = [
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Breakfast Granola",
        subHeading: "Dining Room",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Breakfast Granola",
        subHeading: "Dining Room",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Breakfast Granola",
        subHeading: "Dining Room",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
    ];

  public visibleFeaturedRecipesIndexes: number[] = [];

  public mealTypeCardsContent: {
    imageUrl: string,
    ctaText: string,
    link: string,
  }[] = [
      {
        imageUrl: 'assets/images/card-placeholder.png',
        ctaText: 'See Breakfast Recipes',
        link: "https://google.com"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        ctaText: 'See Dinner Recipes',
        link: "https://google.com"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        ctaText: 'See Snack Recipes',
        link: "https://google.com"
      }
    ];

  public visibleMealTypesIndexes: number[] = [];

  public foodInnovatorFavouritesCardsContent: {
    imageUrl: string,
    title: string,
    subHeading: string,
    description: string,
    link: string,
  }[] = [
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Breakfast Granola",
        subHeading: "Dining Room",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Breakfast Granola",
        subHeading: "Dining Room",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Breakfast Granola",
        subHeading: "Dining Room",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
    ];

  public visibleFoodInnovatorFavouritesIndexes: number[] = [];

  public visibleInnovationDayCardsContentIndexes: number[] = [];

  public innovationDayCardsContent: {
    imageUrl: string,
    title: string,
    description: string,
    link: string,
    linkText: string,
  }[] = [
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop 2",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop 3",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
    ];
}
