import { Component } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [PageHeadingComponent, NgForOf],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {
  faqs: {heading: string, body: string}[] = [
    {heading: "What is the tallest building in the world?", body: "It is tough to say, there are always new buildings coming up. Static content becomes outdated quickly."},
    {heading: "What is the tallest building in the world?", body: "It is tough to say, there are always new buildings coming up. Static content becomes outdated quickly."},
    {heading: "What is the tallest building in the world?", body: "It is tough to say, there are always new buildings coming up. Static content becomes outdated quickly."},
    {heading: "What is the tallest building in the world?", body: "It is tough to say, there are always new buildings coming up. Static content becomes outdated quickly."},
    {heading: "What is the tallest building in the world?", body: "It is tough to say, there are always new buildings coming up. Static content becomes outdated quickly."}
  ];
}
