<app-page-heading heading="Frequently Asked Questions" breadcrumbText="FAQs"></app-page-heading>

<div class="page-body-wrapper">
    <div class="page-body-content">
        <div class="question" *ngFor="let question of faqs">
            <h2>{{question.heading}}</h2>
            <div class="question-body-wrapper">
                <svg class="triangle" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16" height="23" viewBox="0 0 16 23" fill="none">
                    <path
                        d="M1.91238 6.65629C-0.379192 4.07636 1.4523 -6.09701e-05 4.903 -6.0929e-05L16 -6.07967e-05L16 22.5166L1.91238 6.65629Z"
                        fill="#F5F6F6" />
                </svg>
                <p>{{question.body}}</p>
            </div>
        </div>
    </div>
</div>