import { Environment } from './environment.interface';

export const environment: Environment = {
baseUrl: 'https://cdn.contentful.com',
  contentful: {
    space: '3h8j9vctv5nr',
    accessToken: 'wUvQzFyXCw7mLEKHIBtrDi_rkgp_I-oA7rc13DPOj0w',
    contentfulBaseURL: 'https://cdn.contentful.com',
    environment: 'master',
  },
  urlToShowPreviewOptions: 'uat-methodsheets.woolworths.com.au'
};
