import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { NgForOf, NgIf } from '@angular/common';
import { CardComponent } from '../../shared/card/card.component';
import { CardCarouselComponent } from '../../shared/card-carousel/card-carousel.component';
import { SectionHeadingComponent } from '../../shared/section-heading/section-heading.component';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { RecipeService } from '../../services/recipe.service';
import { IFdinRecipe } from '../../shared/interfaces/contentful';
import { MinutesToTimePipe } from '../../shared/pipes/minutes-to-time.pipe';
import { RenderRichTextDirective } from '../../shared/directives/render-rich-text.directive';

@Component({
  selector: 'app-recipe',
  standalone: true,
  imports: [PageHeadingComponent, NgForOf, NgIf, CardComponent, CardCarouselComponent, SectionHeadingComponent, MinutesToTimePipe, RenderRichTextDirective],
  templateUrl: './recipe.component.html',
  styleUrl: './recipe.component.scss'
})
export class RecipeComponent implements OnInit, OnDestroy {

  public slug?: string; //slug of the recipe

  recipeData?: IFdinRecipe;

  public visibleInnovationDayCardsContentIndexes: number[] = [];

  private componentDestroyed = new Subject<void>();

  public innovationDayCardsContent: {
    imageUrl: string,
    title: string,
    description: string,
    link: string,
    linkText: string,
  }[] = [
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop 2",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop 3",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
    ];

  constructor(private _Activatedroute: ActivatedRoute, private _RecipeService: RecipeService) {

  }

  ngOnInit() {
    this.slug = this.getSlugFromRoute();
    this.getRecipeData(this.slug);
  }

  /**
  * The slug of the recipe is in the route as a route param.
  */
  getSlugFromRoute(): string {
    // get the route param
    const slugParam = this._Activatedroute.snapshot.paramMap.get('id');

    if(slugParam){
      return slugParam;
    }

    //TODO: implement errors
    // this.toast.error('slug not provided');
    return '';
  }

  /**
     * Get data for the recipe.
     *
     * @param recipeSlug - slug for the recipe to return.
     */
  getRecipeData(recipeSlug: string) {
    this._RecipeService.getRecipeBySlug(recipeSlug).pipe(takeUntil(this.componentDestroyed)).subscribe((recipeData) => {
      if (!recipeData) {
        //TODO: implement errors
        // this.toast.error('Recipe not found');
        return;
      }

      this.recipeData = recipeData;

    });
  }

  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  share(method: "print" | "pinterest" | "facebook" | "mail") {

  }

}
