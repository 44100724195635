import { Injectable } from '@angular/core';
import { ContentfulService } from '../shared/services/contentful.service';
import { IFdinRecipe } from '../shared/interfaces/contentful';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  constructor(private cms: ContentfulService) {

  }

  /**
   * Gets a recipe from the CMS.
   *
   * @param slug - slug of the recipe to retrieve.
   * @returns The recipe from the
   */
  getRecipeBySlug(slug: string | number): Observable<IFdinRecipe> {
    return this.cms.getContent('fdinRecipe', { 'fields.slug': slug, include: 10 }).pipe(map((res) => res[0] as IFdinRecipe));
  }

}
