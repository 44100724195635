import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-card-search',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './card-search.component.html',
  styleUrl: './card-search.component.scss'
})
export class CardSearchComponent implements OnChanges {
  @Output() matchingCardsEvent = new EventEmitter<any[]>();
  @Input() cards: any[] = [];
  searchTerm: string = "";
  matchingCards: any[] = [];

  constructor() {
  }

  ngOnChanges() {
    this.resetSearch();
  }

  resetSearch() {
    if (!this.searchTerm) {
      this.matchingCards = [];
      this.cards.forEach(card => {
        this.matchingCards.push(card);
      });

      this.matchingCardsEvent.emit(this.matchingCards);
    }
  }

  filterResults() {
    this.matchingCards = [];
    this.cards.forEach(card => {
      if (JSON.stringify(card).toLowerCase().includes(this.searchTerm.toLowerCase())) {
        this.matchingCards.push(card);
      }
    });

    this.matchingCardsEvent.emit(this.matchingCards);
  }
}
