<div class="container" [style.backgroundImage]="'url(' + previewImageUrl +  ')' " *ngIf="!showVideo">
    <div class="title">{{title}}</div>

    <button (click)="showVideo = !showVideo">
        <img src="assets/images/play-button.svg" alt="">
        <span>Watch video</span>
    </button>

    <a class="link" *ngIf="viewMoreLink && viewMoreLinkText" (click)="goToLink()">{{viewMoreLinkText}}</a>
</div>

<iframe id="ytplayer" type="text/html" width="100%" height="100%" *ngIf="videoUrl && showVideo"
  [src]="videoUrl"
  frameborder="0"></iframe>