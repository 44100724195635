import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  catchError,
  interval, map, mergeMap, Observable,
  of
} from 'rxjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveResponse from 'contentful-resolve-response';
import { IEntry } from '../interfaces/contentful';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  public isPreviewModeEnabled = false;

  constructor(private httpClient: HttpClient) { }

  /**
   * Gets content via an api call to our contentful proxy. Depending on the value of the isPreviewModeEnabled member, returns published or preview content.
   *
   * @param contentType - The content type, such as "blogPost" or "iconLink".
   * @param query - Additional query fields for the content request.
   * @returns Matches for requested content.
   */
  getContent(contentType: string, query?: object): Observable<IEntry[]> {
    // combine our query with the content Type to create one single query object
    // Converting the Object to a Query String
    const queryStringWithContentType = new URLSearchParams({ content_type: contentType, ...query }).toString();

    
    if (this.isPreviewModeEnabled) {
      // Generating the header for preview mode
      const headers = new HttpHeaders({ isPreview: `${this.isPreviewModeEnabled}` });
      const contentfulURL = `${environment.contentful.contentfulBaseURL}/spaces/${environment.contentful.space}/environments/${environment.contentful.environment}/entries?${queryStringWithContentType}`;
      return interval(2000).pipe(mergeMap(() => this.httpClient.get<IEntry[]>(contentfulURL, { headers }))).pipe(map((response) => resolveResponse(response) as IEntry[]), catchError(e => { console.log("error fetching content", e); return of(e)}));
    }else{
      // Generating the header for published mode
      const headers = new HttpHeaders({Authorization: 'Bearer ' + environment.contentful.accessToken});
      const contentfulURL = `${environment.contentful.contentfulBaseURL}/spaces/${environment.contentful.space}/environments/${environment.contentful.environment}/entries?${queryStringWithContentType}`;
      return this.httpClient.get<IEntry[]>(contentfulURL, { headers }).pipe(map((response) => resolveResponse(response) as IEntry[]), catchError(e => { console.log("error fetching content", e); return of(e)}));
    }
  }
}
