<div id="landing">
    <div id="landing-content">
        <h1>Inspiring the Future of Food</h1>
        <p>
            Food Innovators are Woolworth's dynamic & talented team of in house Innovation Chef's.
        </p>
        <p>
            We are inspired and driven by our love of good food. We are continually researching new food trends,
            developing new recipes and finding new ingredients to create inspiring new food products for you.
        </p>
        <p>
            Enjoy our new online home & get inspired & informed with recipes, the latest food trends & food stories!
        </p>
        <button class="button">
            Book A Kitchen
        </button>
    </div>

    <div id="landing-photo">
        <img src="assets/images/hero-dish.png" alt="">
    </div>
</div>

<app-video-player class="home-video-player" [isLinkExternal]="true" youtubeVideoId="mD2rWEowr-w" title="Cat Riding a Scooter" viewMoreLink="https://youtube.com" viewMoreLinkText="See More On Youtube" previewImageUrl="assets/images/video-background.png"></app-video-player>

<app-section-heading heading="LEARN ESSENTIAL SKILLS" subHeading="Workshops"></app-section-heading>

<app-card-carousel [itemsLength]="workshopCardsContent.length" (visibleItemIndexes)="visibleWorkshopCardsContentIndexes = $event">
    <app-card
    class="workshop-card"
    *ngFor="let index of visibleWorkshopCardsContentIndexes"
    [cardImageUrl]="workshopCardsContent[index].imageUrl"
    [cardHeading]="workshopCardsContent[index].title"
    [cardBodyContent]="workshopCardsContent[index].description"
    [cardCTALink]="workshopCardsContent[index].link"
    [cardCTAText]="'More Info'"
    [isCTAButton]="false"
    [barContent]="[{iconImageUrl: '/assets/images/search.svg', text: '12th Mar 2024'}, {iconImageUrl: '/assets/images/search.svg', text: '6pm - 9pm'}]"
    >

    </app-card>
</app-card-carousel>

<app-section-heading heading="Innovation Days"></app-section-heading>
<app-card-carousel [itemsLength]="innovationDayCardsContent.length" (visibleItemIndexes)="visibleInnovationDayCardsContentIndexes = $event">
    <app-card class="workshop-card" *ngFor="let index of visibleInnovationDayCardsContentIndexes"
    [cardImageUrl]="innovationDayCardsContent[index].imageUrl"
    [cardHeading]="innovationDayCardsContent[index].title"
    [cardBodyContent]="innovationDayCardsContent[index].description"
    [cardCTALink]="innovationDayCardsContent[index].link"
    [cardCTAText]="'More Info'"
    [isCTAButton]="false"
    >
    </app-card>
</app-card-carousel>