<div id="search-inputs">

    <!-- search input -->
    <form (ngSubmit)="filterResults()">
        <label>
            <span>Search</span>
            <input type="text" [(ngModel)]="searchTerm" name="searchTerm">
        </label>
        <button class="button">
            <span>
                Search
            </span>
            <img src="assets/images/search-white.svg" alt="">
        </button>
    </form>

    <!-- custom filters -->
     <div id="custom-filter-container">
         <ng-content select=".custom-filter">
        </ng-content>
    </div>
</div>
<div id="results-container">
    <ng-content select=".results">
    </ng-content>
</div>
<div id="show-more-container">
    <button>
        Show More
    </button>
</div>