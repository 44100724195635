import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToTime',
  standalone: true
})
export class MinutesToTimePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    if(parseInt('' + value) < 60){
      return value + " minutes";
    }else{
      let numberOfHours = Math.round( (parseInt('' + value) / 60) * 10) / 10;

      if(numberOfHours != 1){
        return numberOfHours + " hours";
      }else{
        return "1 hour";
      }
    }
  }

}
