<div class="carousel-button">
    <button aria-label="View more items" (click)="scrollItems('left')">
        <img src="assets/images/chevron-left.svg" alt="">
    </button>
</div>

<div class="carousel-content">
    <ng-content>
        
    </ng-content>
</div>

<div class="carousel-button">
    <button aria-label="View more items" (click)="scrollItems('right')">
        <img src="assets/images/chevron-right.svg" alt="">
    </button>
</div>

<div class="responsive-buttons">
    <button aria-label="View more items" (click)="scrollItems('left')">
        <img src="assets/images/chevron-left.svg" alt="">
    </button>
    <button aria-label="View more items" (click)="scrollItems('right')">
        <img src="assets/images/chevron-right.svg" alt="">
    </button>
</div>