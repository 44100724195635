<app-page-heading heading="Contact Us" breadcrumbText="Contact Us"></app-page-heading>


<div class="page-body-wrapper">
    <div class="page-body-content">
        <div id="contact-information">
            <div class="row">
                <h2>Get in touch</h2>
                <p>Email Us - foodinnovators&#64;woolworths.com.au</p>

                <h3>Address</h3>
                <p>1 Woolworths Way, Bella Vista NSW 2153, Australia</p>
            </div>
            <div class="row">
                <h3>Connect with us on social media</h3>
                <div id="social-icons">
                    <a href="https://www.facebook.com/foodinnovators/">
                        <img src="assets/images/facebook.png" alt="">
                    </a>
                    <a href="https://www.instagram.com/innovatorsfood/">
                        <img src="assets/images/instagram.png" alt="">
                    </a>
                    <a href="https://www.youtube.com/woolworthsau">
                        <img src="assets/images/youtube.png" alt="">
                    </a>

                </div>
            </div>
        </div>

        <div id="map">
            <iframe style="border:0; width:100%;" loading="lazy" allowfullscreen
                referrerpolicy="no-referrer-when-downgrade" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCtKKbEsTSSIDnLuQW1b3sl6CzyLzGwxds
              &q=Woolworths Metro Norwest">
            </iframe>
        </div>
    </div>
</div>