import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { WorkshopsComponent } from './pages/workshops/workshops.component';
import { WhatWeDoComponent } from './pages/what-we-do/what-we-do.component';
import { FaqComponent } from './pages/faq/faq.component';
import { MeetTheTeamComponent } from './pages/meet-the-team/meet-the-team.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { RecipeComponent } from './pages/recipe/recipe.component';
import { RecipesComponent } from './pages/recipes/recipes.component';

export const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'workshops', component: WorkshopsComponent},
    {path: 'recipe/:recipeId', component: RecipeComponent},
    {path: 'recipes', component: RecipesComponent},
    {path: 'contact-us', component: ContactUsComponent},
    {path: 'meet-the-team', component: MeetTheTeamComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'what-we-do', component: WhatWeDoComponent},
];
