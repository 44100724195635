import { Component } from '@angular/core';
import { VideoPlayerComponent } from '../../shared/video-player/video-player.component';
import { SectionHeadingComponent } from '../../shared/section-heading/section-heading.component';
import { CardCarouselComponent } from '../../shared/card-carousel/card-carousel.component';
import { NgFor } from '@angular/common';
import { CardComponent } from '../../shared/card/card.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [VideoPlayerComponent, SectionHeadingComponent, CardCarouselComponent, CardComponent, NgFor],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  public visibleWorkshopCardsContentIndexes: number[] = [];

  public workshopCardsContent: {
    imageUrl: string,
    startDate: number,
    endDate: number,
    title: string,
    description: string,
    link: string,
  }[] = [
      {
        imageUrl: 'assets/images/card-placeholder.png',
        startDate: Date.now(),
        endDate: Date.now(),
        title: "Flavors of Thai Workshop",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        startDate: Date.now(),
        endDate: Date.now(),
        title: "Flavors of Thai Workshop 2",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        startDate: Date.now(),
        endDate: Date.now(),
        title: "Flavors of Thai Workshop 3",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
    ];

  public visibleInnovationDayCardsContentIndexes: number[] = [];

  public innovationDayCardsContent: {
    imageUrl: string,
    title: string,
    description: string,
    link: string,
    linkText: string,
  }[] = [
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop 2",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop 3",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
    ];
}
