import { Component } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { AlertComponent } from '../../shared/alert/alert.component';
import { SectionHeadingComponent } from '../../shared/section-heading/section-heading.component';
import { CardCarouselComponent } from '../../shared/card-carousel/card-carousel.component';
import { CardComponent } from '../../shared/card/card.component';
import { NgForOf } from '@angular/common';
import { CardSearchComponent } from '../../shared/card-search/card-search.component';

@Component({
  selector: 'app-workshops',
  standalone: true,
  imports: [PageHeadingComponent, AlertComponent, SectionHeadingComponent, CardCarouselComponent, CardComponent, NgForOf, CardSearchComponent],
  templateUrl: './workshops.component.html',
  styleUrl: './workshops.component.scss'
})
export class WorkshopsComponent {

  public visiblePastWorkshopCardsContentIndexes: number[] = [];

  public pastWorkshopCardsContent: {
    imageUrl: string,
    startDate: number,
    endDate: number,
    title: string,
    description: string,
    link: string,
  }[] = [
      {
        imageUrl: 'assets/images/card-placeholder.png',
        startDate: Date.now(),
        endDate: Date.now(),
        title: "Flavors of Thai Workshop",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        startDate: Date.now(),
        endDate: Date.now(),
        title: "Flavors of Thai Workshop 2",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        startDate: Date.now(),
        endDate: Date.now(),
        title: "Flavors of Thai Workshop 3",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com"
      },
    ];

  public visibleInnovationDayCardsContentIndexes: number[] = [];

  public innovationDayCardsContent: {
    imageUrl: string,
    title: string,
    description: string,
    link: string,
    linkText: string,
  }[] = [
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop 2",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Flavors of Thai Workshop 3",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
    ];

  public workshopsSearchCardsContent: {
    imageUrl: string,
    title: string,
    description: string,
    link: string,
    linkText: string,
  }[] = [
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Thai",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Chicken",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Egg",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Beef",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
      {
        imageUrl: 'assets/images/card-placeholder.png',
        title: "Curry",
        description: "Have you always wanted to make a Thai paste from scratch? How many times have you had a Pad Thai",
        link: "https://google.com",
        linkText: "Watch Video"
      },
    ];

  public matchingWorkshopsSearchCardsContent: {
    imageUrl: string,
    title: string,
    description: string,
    link: string,
    linkText: string,
  }[] = [];
}
