<app-page-heading heading="Workshops" breadcrumbText="Workshops"></app-page-heading>

<div class="page-body-wrapper">
    <div class="page-body-content">
        <app-alert
            text="Due to COVID 19 our Workshops are on currently on hold. We hope to reopen in the future. Please follow us on facebook for the latest updates!"></app-alert>
        <p class="text-center">
            Join us in our state of the art cooking school for a fun, informative & totally inspiring Workshop - enjoy a
            glass of wine while you watch out talented team of innovation chef’s demonstrating the recipes in our food
            theatre before getting hands on with the cooking yourself in our multi station cooking school, then sit down
            afterwards to enjoy the fruits of your labour with your new friends!
        </p>

        <app-section-heading [heading]="'Check Out Some of Our Past Workshops Below'"></app-section-heading>

    </div>
    <div class="page-body-content-full-width">
        <app-card-carousel [itemsLength]="pastWorkshopCardsContent.length"
            (visibleItemIndexes)="visiblePastWorkshopCardsContentIndexes = $event">
            <app-card class="workshop-card" *ngFor="let index of visiblePastWorkshopCardsContentIndexes"
                [cardImageUrl]="pastWorkshopCardsContent[index].imageUrl"
                [cardHeading]="pastWorkshopCardsContent[index].title"
                [cardBodyContent]="pastWorkshopCardsContent[index].description"
                [cardCTALink]="pastWorkshopCardsContent[index].link" [cardCTAText]="'More Info'" [isCTAButton]="false"
                [barContent]="[{iconImageUrl: '/assets/images/search.svg', text: '12th Mar 2024'}, {iconImageUrl: '/assets/images/search.svg', text: '6pm - 9pm'}]">

            </app-card>
        </app-card-carousel>

    </div>
    <div class="page-body-content">
        <app-section-heading [heading]="'Search Workshops'"></app-section-heading>

        <app-card-search (matchingCardsEvent)="matchingWorkshopsSearchCardsContent = $event" [cards]="workshopsSearchCardsContent">

            <div class="results">
                <app-card class="workshop-card" *ngFor="let card of matchingWorkshopsSearchCardsContent"
                [cardImageUrl]="card.imageUrl"
                [cardHeading]="card.title"
                [cardBodyContent]="card.description"
                [cardCTALink]="card.link" [cardCTAText]="'More Info'" [isCTAButton]="false"
                [barContent]="[{iconImageUrl: '/assets/images/search.svg', text: '12th Mar 2024'}, {iconImageUrl: '/assets/images/search.svg', text: '6pm - 9pm'}]">

            </app-card>
            </div>

        </app-card-search>


        <app-section-heading heading="Innovation Days"></app-section-heading>
    </div>

    <div class="page-body-content-full-width">


        <app-card-carousel [itemsLength]="innovationDayCardsContent.length"
            (visibleItemIndexes)="visibleInnovationDayCardsContentIndexes = $event">
            <app-card class="workshop-card" *ngFor="let index of visibleInnovationDayCardsContentIndexes"
                [cardImageUrl]="innovationDayCardsContent[index].imageUrl"
                [cardHeading]="innovationDayCardsContent[index].title"
                [cardBodyContent]="innovationDayCardsContent[index].description"
                [cardCTALink]="innovationDayCardsContent[index].link" [cardCTAText]="'More Info'" [isCTAButton]="false">

            </app-card>
        </app-card-carousel>
    </div>
</div>