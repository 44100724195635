<app-page-heading heading="What we do" breadcrumbText="What we do"></app-page-heading>


<div class="page-body-wrapper">
    <div class="page-body-content">
        
        <!-- video -->
        <app-video-player
        youtubeVideoId="https://www.youtube.com/watch?v=RZ_0ImDYrPY"
        previewImageUrl="https://i.pinimg.com/736x/46/2d/a3/462da304e299554ccb15de97dd66c8a2.jpg"
        viewMoreLink="https://youtube.com"
        viewMoreLinkText="See More On Youtube" 
        previewImageUrl="assets/images/video-background.png"
        [isLinkExternal]="true"
        title="Let Us Show You"
        >

        </app-video-player>

        <!-- body columns -->
        <div >

        </div>
    </div>
</div>