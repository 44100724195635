import { Component } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { CardComponent } from '../../shared/card/card.component';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-meet-the-team',
  standalone: true,
  imports: [PageHeadingComponent, CardComponent, NgForOf],
  templateUrl: './meet-the-team.component.html',
  styleUrl: './meet-the-team.component.scss'
})
export class MeetTheTeamComponent {
  public meetTheTeamCardsContent: {
    imageUrl: string,
    name: string,
    title: string,
    bio: string
  }[] = [
      {
        imageUrl: "assets/images/team-member.png",
        name: "Name Here",
        title: "Title here",
        bio: "bio here"
      },
      {
        imageUrl: "assets/images/team-member.png",
        name: "Name Here",
        title: "Title here",
        bio: "bio here"
      },
      {
        imageUrl: "assets/images/team-member.png",
        name: "Name Here",
        title: "Title here",
        bio: "bio here"
      },
      {
        imageUrl: "assets/images/team-member.png",
        name: "Name Here",
        title: "Title here",
        bio: "bio here"
      },
      {
        imageUrl: "assets/images/team-member.png",
        name: "Name Here",
        title: "Title here",
        bio: "bio here"
      }

    ];
}
