import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { BLOCKS, Document, MARKS } from '@contentful/rich-text-types';
import { ContentfulService } from '../services/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

@Directive({
  standalone:true,
  selector: '[appRenderRichText]'
})

/**
 * Content from contentful is sent in document tree format.
 * Apply this directive to a div and pass it the document from contentful to have it render the html within the div it is applied to.
 */
export class RenderRichTextDirective {

  @Input() appRenderRichText?: Document | any;

  constructor(private el: ElementRef, private cms: ContentfulService, private renderer: Renderer2) {
    this.renderer.addClass(el.nativeElement, 'contentfulRichText');
  }

  ngOnChanges() {
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
          if(node.data.target.fields.file.contentType.includes("image")){
            return `<img class="richTextImage" src="${node.data.target.fields.file.url}" alt=${node.data.target.fields.file.description}>`
          }

          if(node.data.target.fields.file.contentType.includes("vide")){
            return ` <video class="richTextVideo" controls width="100%" height="100%" [muted]="true" src="${node.data.target.fields.file.url}"  loop="true" muted="true" autoplay="true">
            </video>`
          }

          return '';
        }
      },
    }
    if (this.appRenderRichText) {
      this.el.nativeElement.innerHTML = documentToHtmlString(this.appRenderRichText, options);
    } else {
      this.el.nativeElement.innerHTML = "";
    }
  }

}
