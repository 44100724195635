<!-- mosaic -->
<div id="mosaic">
    <app-section-heading heading="We Offer a Lot More"></app-section-heading>

    <div id="mosaic-columns-wrapper">
        <div class="mosaic-col">
            <div class="mosaic-card" id="mosaic-card-1">
                <div class="mosaic-card-image"
                    [style.background-image]="'url(' + 'assets/images/mosaic-placeholder.png' + ')' ">

                </div>
                <div class="mosaic-card-footer">
                    <div>
                        Meet the Team
                    </div>
                    <img src="assets/images/arrow-right.svg" alt="">
                </div>
            </div>
            <div class="mosaic-card" id="mosaic-card-2">
                <div class="mosaic-card-image"
                    [style.background-image]="'url(' + 'assets/images/mosaic-placeholder.png' + ')' ">

                </div>
                <div class="mosaic-card-footer">
                    <div>
                        Meet the Team
                    </div>
                    <img src="assets/images/arrow-right.svg" alt="">
                </div>
            </div>
        </div>

        <div class="mosaic-col">
            <div class="mosaic-card" id="mosaic-card-3">
                <div class="mosaic-card-image"
                    [style.background-image]="'url(' + 'assets/images/mosaic-placeholder.png' + ')' ">

                </div>
                <div class="mosaic-card-footer">
                    <div>
                        Meet the Team
                    </div>
                    <img src="assets/images/arrow-right.svg" alt="">
                </div>
            </div>
            <div class="mosaic-card" id="mosaic-card-4">
                <div class="mosaic-card-image"
                    [style.background-image]="'url(' + 'assets/images/mosaic-placeholder.png' + ')' ">

                </div>
                <div class="mosaic-card-footer">
                    <div>
                        Meet the Team
                    </div>
                    <img src="assets/images/arrow-right.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</div>


<!-- cta -->
<div id="mailing-list-cta">
    <h2>Deliciousness to your inbox</h2>
    <p>Signup here for updates from us!</p>

    <div class="cta-input">
        <input type="text">
        <button>Sign Up</button>
    </div>
</div>

<!-- footer links -->
<div id="footer-links">

    <div class="footer-column" id="logo-column">
        <img src="assets/images/logo.png" alt="" class="fi-logo">
    </div>
    <div class="footer-column">
        <div class="column-heading">Menu</div>
        <ul>
            <li>
                <a href="">
                    What we Do
                </a>
            </li>
            <li>
                <a>
                    Book a Kitchen
                </a>
            </li>
            <li>
                <a>
                    Resources
                </a>
            </li>
            <li>
                <a>
                    The Library
                </a>
            </li>
        </ul>
    </div>
    <div class="footer-column">
        <div class="column-heading">Other Links</div>
        <ul>
            <li>
                <a [routerLink]="'faq'">
                    FAQ
                </a>
            </li>
            <li>
                <a href="">
                    Innovation Chef
                </a>
            </li>
            <li>
                <a href="">
                    Junior Innovation Chef Program
                </a>
            </li>
            <li>
                <a href="">
                    Workshops
                </a>
            </li>
            <li>
                <a [routerLink]="'contact-us'">
                    Contact Us
                </a>
            </li>
        </ul>
    </div>
    <div class="footer-column social-column">
        <div class="column-heading">Connect With Us</div>

        <div id="social-media-icons">
            <a href="">
                <img src="assets/images/facebook.svg" alt="Facebook">
            </a>

            <a href="">
                <img src="assets/images/instagram.svg" alt="Instagram">
            </a>

            <a href="">
                <img src="assets/images/youtube.svg" alt="Youtube">
            </a>
        </div>

        <div class="address">
            <div>1 Woolworths Way</div>
            <div>1 Woolworths Way, Bella Vista NSW 2153, Australia</div>
        </div>

        <button>
            <span>Contact Us</span>
            <img src="assets/images/mail-icon.svg" alt="">
        </button>
    </div>

</div>

<!-- footer bar -->
<div id="bottom-bar">
    <div>
        © Woolworths Ltd. 2019. All rights reserved. ABN 88 000 014 675 
    </div>

    <div>
        <a href="">
            Terms and Policy
        </a>
    </div>
</div>