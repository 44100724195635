import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-carousel',
  standalone: true,
  imports: [],
  templateUrl: './card-carousel.component.html',
  styleUrl: './card-carousel.component.scss'
})
export class CardCarouselComponent implements OnInit{
  @Input() itemsLength!: number;

  @Output() visibleItemIndexes: EventEmitter<number[]> = new EventEmitter();

  private maxItemsVisible: number = 3;

  private startIndex = 0;
  

  ngOnInit(){
    this.buildAndEmitIndexArray();
  }

  /**
   * Update the visibileItemIndexes to represent the movement of the carousel
   * in a specific direction.
   * @param direction which way to move the carousel items
   */
  public scrollItems(direction: "left" | "right") {
    if (direction === "left") {
      this.startIndex--;
    } else {
      this.startIndex++;
    }

    if (this.startIndex < 0) {
      this.startIndex = this.itemsLength - 1;
    }

    if (this.startIndex >= this.itemsLength) {
      this.startIndex = 0;
    }

    this.buildAndEmitIndexArray();

  }

  private buildAndEmitIndexArray() {
    let newVisibleItemsIndexes = [];
    let indexToAdd = this.startIndex;


    for (let index = 0; index < this.maxItemsVisible; index++) {
      if (indexToAdd >= this.itemsLength) {
        indexToAdd = 0;
      }

      newVisibleItemsIndexes.push(indexToAdd);

      indexToAdd++;
    }

    this.visibleItemIndexes.emit(newVisibleItemsIndexes);
  }
}
