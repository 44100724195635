import { NgIf } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-video-player',
  standalone: true,
  imports: [NgIf],
  templateUrl: './video-player.component.html',
  styleUrl: './video-player.component.scss'
})
export class VideoPlayerComponent implements OnChanges{
  @Input() youtubeVideoId?: string;
  @Input() previewImageUrl?: string;
  @Input() viewMoreLink?: string;
  @Input() viewMoreLinkText?: string;
  @Input() isLinkExternal?: boolean;
  @Input() title?: string;
  videoUrl?: SafeUrl;

  showVideo = false;

  constructor(private router: Router, private sanitizer: DomSanitizer) {

  }

  ngOnChanges(){
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.youtubeVideoId);
  }

  goToLink() {
    if (!this.viewMoreLink) {
      console.error("View more link not provided in video player component");
    }

    if (this.isLinkExternal) {
      window.open(this.viewMoreLink);
    } else {
      this.router.navigateByUrl('' + this.viewMoreLink);
    }
  }
}
