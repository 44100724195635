import { NgFor, NgForOf, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-header-bar',
  standalone: true,
  imports: [NgFor, NgForOf, NgIf, RouterModule],
  templateUrl: './header-bar.component.html',
  styleUrl: './header-bar.component.scss'
})
export class HeaderBarComponent {

  isAnyMenuItemOpen = false;
  isMobileMenuOpen = false;

  menuItems: {text: string, isOpen?: boolean, items?: {text: string, link: string}[], link?: string}[] = [
    {text: "What we do", items: [
      {text: "Workshops", link: "/workshops"},
      {text: "Team", link: "/home"},
      {text: "Junior Chef Innovation Program", link: "/home"},
      {text: "Contact Us", link: "/contact-us"},
      {text: "Food Safaris", link: "/home"},
    ]},
    {text: "Book a Kitchen", link: "/book-a-kitchen"},
    {text: "Resources", items: [
      {text: "FAQs", link: "/faq"},
      {text: "Resource 2", link: "/link"}
    ]},
    {text: "Recipes", items: [
      {text: "Recipe 1", link: "/link"},
      {text: "Recipe 2", link: "/link"}
    ]},
    {text: "The Library", items: [
      {text: "Recipe 1", link: "/link"},
      {text: "Recipe 2", link: "/link"}
    ]}
  ];

  constructor(private router: Router){

  }


  public openMenuItem(menuItem: any){
    this.menuItems.forEach(item => {
      item.isOpen = false;
    });

    menuItem['isOpen'] = true;
    this.isAnyMenuItemOpen = true;
  }

  public closeAllMenuItems(){
    this.menuItems.forEach(item => {
      item.isOpen = false;
    });

    this.isAnyMenuItemOpen = false;
  }

  public toggleMobileMenu(){
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  public navigateTo(route: string){
    this.router.navigateByUrl(route);
  }


  public toggleMenuItem(menuItem: any){
    menuItem['isOpen'] = !menuItem['isOpen'];
  }
}
