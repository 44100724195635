import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [NgFor, NgIf],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  @Input() barContent: {iconImageUrl: string, text: string}[] = []; 
  @Input() cardImageUrl?: string;
  @Input() cardHeading?: string;
  @Input() cardSubHeading?: string;
  @Input() cardBodyContent?: string;
  @Input() cardCTALink?: string;
  @Input() cardCTAText?: string;
  @Input() isCTAButton?: boolean = true;
  @Input() cardTag?: string;
  @Input() showSocialLinks: boolean = false;

  constructor(private router: Router){

  }

  share(method: "print" | "pinterest" | "facebook" | "mail"){

  }

  public goToLink(){
    if(!this.cardCTALink){
      return;
    }
    
    this.router.navigateByUrl(this.cardCTALink);
  }
}
